import Vue from 'vue';

import {
  Button,
  Input,
  Carousel,
  CarouselItem,
  Message,
  MessageBox,
  Dialog,
  Form,
  FormItem,
  Cascader,
  Pagination,
  InputNumber,
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  RadioButton,
  RadioGroup,
  Switch,
  Select,
  Option,
} from 'element-ui';

Vue.use(Button);
Vue.use(Input);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Cascader);
Vue.use(Pagination);
Vue.use(InputNumber);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Divider);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

import { getBaiduCodeList } from '../api/home';
import { getSession, setSession } from '../utils/storage';

function createStatistical(bd_id, fullPath) {
  document.getElementById(`baidu_tj${bd_id}`) && document.getElementById(`baidu_tj${bd_id}`).remove();
  let hm = document.createElement('script');
  hm.src = `https://hm.baidu.com/hm.js?${bd_id}`;
  hm.id = `baidu_tj${bd_id}`;
  let src = document.getElementsByTagName('script')[0];
  src.parentNode.insertBefore(hm, src);
  // 文章统计
  try {
    window._hmt = window._hmt || [];
    window._hmt.push(['_setAccount', bd_id]);
    window._hmt.push(['_trackPageview', fullPath]);
  } catch (e) {
    console.log(e);
  }
}

export default ({ app, store }) => {
  // 获取文章百度统计
  getBaiduCodeList({}).then((resData) => {
    console.log(resData);
    setSession('statistical_bd', resData.data);
  });
  app.router.afterEach((to, from) => {
    // 文章统计
    if (to.path == '/transverse/articleDetails') {
      // 文章统计配置
      const statisticalList = JSON.parse(getSession('statistical_bd') || []);
      let resultsData = statisticalList.find((item) => {
        return item.id == to.query.id;
      });
      if (resultsData) {
        createStatistical(resultsData.baiduCode, to.fullPath);
      }
    }
  });
};